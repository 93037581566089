import React from "react";
import "./preview.css";

const Preview = () => (
  <React.Fragment>
    <div id="preview">
      <div className="step">
        <span className="step__label" data-label="01">
          Vision
        </span>
        <p>
          To become a full-service remodeling agency for clients looking to
          design their dream home.
        </p>
      </div>
      <div className="step">
        <span className="step__label" data-label="02">
          Mission
        </span>
        <p>
          Our mission is to provide our clients with the means and opportunity
          to create their dream home whether their budget is big or small.
        </p>
      </div>
      <div className="step">
        <span className="step__label" data-label="03">
          Objectives
        </span>
        <ul>
          <li>
            To create a desire for our products and services through advertising
            and word of mouth.
          </li>
          <li>Generate $300,000 in sales annually.</li>
          <li>
            To create a vibrant social media presence that will showcase our
            work and increase brand awareness.
          </li>
          <li>
            Create trusted partnerships with national contractors and service
            providers.
          </li>
          <li>Maintain a steady flow of projects and residential contracts.</li>
        </ul>
      </div>
      <div className="step">
        <span className="step__label" data-label="04">
          Strategies
        </span>
        <ul>
          <li>
            Set up reliable supply chains for quality furnishings and building
            materials.
          </li>
          <li>
            Launch company webpage, Facebook account, Instagram, and Twitter
            account.
          </li>
          <li>
            Attend national design conferences to analyze industry trends and
            source potential clients.
          </li>
          <li>
            Advertise services through social media, local leaflets, and buzz
            campaigns.
          </li>
          <li>Receive referrals and ratings from satisfied clients.</li>
        </ul>
      </div>
      <div className="step">
        <span className="step__label" data-label="05">
          Action Items
        </span>
        <ul>
          <li>
            Contract the services of a web developer and social media marketing
            specialist.
          </li>
          <li>
            Negotiate wholesale prices with suppliers and building contractors.
          </li>
          <li>Book tickets for the top design conferences.</li>
          <li>
            Contact print media advertising agencies for quotes on leaflet
            advertising.
          </li>
          <li>Create a portfolio of completed projects.</li>
          <li>Ask former clients for referrals.</li>
        </ul>
      </div>
    </div>
    <p>Below here is an iframe: Opacity 0, Width 0, Height 0</p>
    <iframe title="preview" id="iframe" />
  </React.Fragment>
);

export default Preview;
