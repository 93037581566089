import React from "react";
import { detect } from "detect-browser";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Preview from "./components/preview/index";

const App = () => {
  const handlePrint = () => {
    const browser = detect();
    const doc = new jsPDF("p", "mm", "letter");
    const html = document.getElementById("preview");

    html2canvas(html, {
      scale: 3,
    }).then((canvas) => {
      const imagedata = canvas.toDataURL("image/png", 1);

      // TODO: For every box, increment height by 50
      //            Canvas     Type   X   Y   W    H
      doc.addImage(imagedata, "JPEG", 27, 10, 160, 160);
      doc.autoPrint();
      const iframe = document.getElementById("iframe");

      switch (browser && browser.name) {
        case "chrome":
        case "edge":
          iframe.src = doc.output("bloburl");
          break;
        case "firefox":
          window.open(doc.output("bloburl"));
          break;
        default:
          break;
      }
    });
  };

  return (
    <div className="container">
      <div className="form-group">
        <button onClick={handlePrint}>Print</button>
      </div>
      <Preview handlePrint={handlePrint} />
    </div>
  );
};

export default App;
